import PagedList from 'models/accessors/pagedList';
import loanApplicationService from '../../../accessors/loanApplicationService';
import LoanApplication from '../../../models/loan/loanApplication';
import { AppThunk } from '../../../redux/store/store';
import { ASSIGN_LOAN_OFFICER, LOAN_ADD_NOTE, LOAN_DELETE_NOTE, LOAN_UPDATE_NOTE, PAGED_LOAN_APPLICATIONS, LOAN_APPLICATION_DETAILS, LOAN_UPDATE_STATUS, LOAN_ADD_OFFER, LOAN_ADD_DOCUMENT, LOAN_DELETE_DOCUMENT, LOAN_OPENREQUESTS } from '../../store/loan/types';
import { TOGGLE_API_REQUEST_SUCCESS, TOGGLE_LOADING, TOGGLE_MODAL_LOADING } from '../../store/system/types';
import LoanNote from 'models/note/loanNote';
import BankEmployee from 'models/bank/bankEmployee';
import LoanOffer from 'models/loanOffer/loanOffer';
import LoanDocument from 'models/loanDocument/loanDocument';
import AddDocumentsRequest from 'models/addDocumentsRequest/AddDocumentsRequest';
import DocumentRequest from 'models/documentData/DocumentRequest';
import LoanApplicationFilterCriteria from 'models/accessors/LoanApplicationFilterCriteria'
import { LoanStatus } from 'shared/Enums';

export const getLoanApplications = (
    filterCriteria: LoanApplicationFilterCriteria
): AppThunk => async (dispatch) => {
    loanApplicationService.GetLoanApplications(filterCriteria)
        .then((pagedList: PagedList<LoanApplication>) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            dispatch({
                type: PAGED_LOAN_APPLICATIONS,
                payload: {
                    pagedLoanApplications: pagedList
                },
            });
        })
};

export const getLoanApplicationDetails = (id: number): AppThunk => async (dispatch) => {
    loanApplicationService.GetLoanApplicationDetails(id)
        .then((loan: LoanApplication) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });
            
            dispatch(findOpenDocumentRequests(loan.customerAccount.id));
            
            dispatch({
                type: LOAN_APPLICATION_DETAILS,
                payload: {
                    loanApplicationDetails: loan
                },
            });
        })
};

export const assignLoanOfficerToLoan = (
    loanId: number,
    employeeId: number
): AppThunk => async (dispatch) => {
    loanApplicationService.AssignLoanOfficerToLoan(loanId, employeeId)
        .then((bankEmployee: BankEmployee) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            dispatch({
                type: ASSIGN_LOAN_OFFICER,
                payload: {
                    bankEmployee: bankEmployee
                },
            });
        })
};

export const addLoanNote = (
    loanNote: LoanNote
): AppThunk => async (dispatch) => {
    loanApplicationService.AddLoanNote(loanNote)
        .then((newNote: LoanNote) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            // We are returning a blank object if there was an error
            if (Object.keys(newNote).length > 0) {
                dispatch({
                    type: LOAN_ADD_NOTE,
                    payload: {
                        loanNote: newNote
                    },
                });

                dispatch({
                    type: TOGGLE_API_REQUEST_SUCCESS,
                    payload: {
                        apiRequestSuccess: true
                    },
                });
            }
        })
};

export const updateLoanNote = (
    loanNote: LoanNote
): AppThunk => async (dispatch) => {
    loanApplicationService.UpdateLoanNote(loanNote)
        .then(success => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            // We are returning a blank object if there was an error
            if (success) {
                dispatch({
                    type: LOAN_UPDATE_NOTE,
                    payload: {
                        updatedLoanNote: loanNote
                    },
                });

                dispatch({
                    type: TOGGLE_API_REQUEST_SUCCESS,
                    payload: {
                        apiRequestSuccess: true
                    },
                });
            }
        })
};

export const deleteLoanNote = (
    loanNoteId: number
): AppThunk => async (dispatch) => {
    loanApplicationService.DeleteLoanNote(loanNoteId)
        .then((success: boolean) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            // We are returning a blank object if there was an error
            if (success) {
                dispatch({
                    type: LOAN_DELETE_NOTE,
                    payload: {
                        loanNoteId: loanNoteId
                    },
                });

                dispatch({
                    type: TOGGLE_API_REQUEST_SUCCESS,
                    payload: {
                        apiRequestSuccess: true
                    },
                });
            }
        })
};

export const updateLoanStatus = (
    loanId: number,
    status: number
): AppThunk => async (dispatch) => {
    loanApplicationService.UpdateLoanStatus(loanId, status)
        .then((success: boolean) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            if (success) {
                dispatch({
                    type: LOAN_UPDATE_STATUS,
                    payload: {
                        loanStatus: status
                    },
                });

                dispatch({
                    type: TOGGLE_API_REQUEST_SUCCESS,
                    payload: {
                        apiRequestSuccess: true
                    },
                });
            }
        })
};

export const addLoanOffer = (
    loanOffer: LoanOffer
): AppThunk => async (dispatch) => {
    loanApplicationService.AddLoanOffer(loanOffer)
        .then((newOffer: LoanOffer) => {
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });

            // We are returning a blank object if there was an error
            if (Object.keys(newOffer).length > 0) {
                dispatch({
                    type: LOAN_ADD_OFFER,
                    payload: {
                        loanOffer: newOffer
                    },
                });

                dispatch({
                    type: TOGGLE_API_REQUEST_SUCCESS,
                    payload: {
                        apiRequestSuccess: true
                    },
                });
            }
        })
};

export const addLoanDocument = (
    loanDocuments: AddDocumentsRequest
): AppThunk => async (dispatch) => {
    loanApplicationService.AddLoanDocument(loanDocuments)
        .then((newDocuments: LoanDocument[]) => {
            dispatch({
                type: TOGGLE_MODAL_LOADING,
                payload: { isModalLoading: false }
            });
            // We are returning a blank object if there was an error
            if (newDocuments.length > 0) {
                dispatch({
                    type: LOAN_ADD_DOCUMENT,
                    payload: {
                        loanDocuments: newDocuments
                    },
                });

                dispatch({
                    type: TOGGLE_API_REQUEST_SUCCESS,
                    payload: {
                        apiRequestSuccess: true
                    },
                });
            }
        })
};

export const deleteLoanDocument = (
    loanDocumentId: number
): AppThunk => async (dispatch) => {
    loanApplicationService.DeleteLoanDocument(loanDocumentId)
        .then((success: boolean) => {
            dispatch({
                type: TOGGLE_MODAL_LOADING,
                payload: { isModalLoading: false }
            });

            // We are returning a blank object if there was an error
            if (success) {
                dispatch({
                    type: LOAN_DELETE_DOCUMENT,
                    payload: {
                        loanDocumentId: loanDocumentId
                    },
                });

                dispatch({
                    type: TOGGLE_API_REQUEST_SUCCESS,
                    payload: {
                        apiRequestSuccess: true
                    },
                });
            }
        })
};

export const deleteDocumentRequest = (
    documentRequestId: number, customerAccountId: number
): AppThunk => async (dispatch) => {
    loanApplicationService.DeleteDocumentRequest(documentRequestId)
        .then((success: boolean) => {
            dispatch({
                type: TOGGLE_MODAL_LOADING,
                payload: { isModalLoading: false }
            });            
            
            dispatch(findOpenDocumentRequests(customerAccountId));

            dispatch({
                type: TOGGLE_API_REQUEST_SUCCESS,
                payload: {
                    apiRequestSuccess: true
                },
            });
        })
};


export const findOpenDocumentRequests = (
    customerAccountId: number
): AppThunk => async (dispatch) => {
    loanApplicationService.FindOpenDocumentRequests(customerAccountId)
        .then((data: DocumentRequest[]) => {            
            if (data) {
                dispatch({
                    type: LOAN_OPENREQUESTS,
                    payload: {
                        openDocumentRequests: data
                    },
                });

                dispatch({
                    type: TOGGLE_API_REQUEST_SUCCESS,
                    payload: {
                        apiRequestSuccess: true
                    },
                });
            }
        })
};


export const addDocumentRequests = (
    requests: DocumentRequest[], customerAccountId: number
): AppThunk => async (dispatch) => {
    loanApplicationService.AddDocumentRequests(requests)
        .then(() => {
            dispatch(findOpenDocumentRequests(customerAccountId));
            dispatch({
                type: TOGGLE_LOADING,
                payload: { isLoading: false }
            });   
            dispatch({
                type: LOAN_UPDATE_STATUS,
                payload: {
                    loanStatus: LoanStatus.NeedsInput
                },
            });         
        })
};