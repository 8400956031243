import PagedList from "models/accessors/pagedList";
import LoanNote from "models/note/loanNote";
import LoanApplication from "../../../models/loan/loanApplication";
import BankEmployee from "../../../models/bank/bankEmployee";
import LoanOffer from "models/loanOffer/loanOffer";
import LoanDocument from "models/loanDocument/loanDocument";
import DocumentRequest from "models/documentData/DocumentRequest";

export const PAGED_LOAN_APPLICATIONS = 'GET_PAGED_LOAN_APPLICATIONS';
export const LOAN_APPLICATION_DETAILS = 'GET_LOAN_APPLICATION_DETAILS';
export const ASSIGN_LOAN_OFFICER = 'ASSIGN_LOAN_OFFICER';
export const LOAN_ADD_NOTE = 'LOAN_ADD_NOTE';
export const LOAN_DELETE_NOTE = 'LOAN_DELETE_NOTE';
export const LOAN_UPDATE_NOTE = 'LOAN_UPDATE_NOTE';
export const LOAN_UPDATE_STATUS = 'LOAN_UPDATE_STATUS';
export const LOAN_ADD_OFFER = 'LOAN_ADD_OFFER';
export const LOAN_ADD_DOCUMENT = 'LOAN_ADD_DOCUMENT';
export const LOAN_DELETE_DOCUMENT = 'LOAN_DELETE_DOCUMENT';
export const LOAN_OPENREQUESTS = 'LOAN_OPENREQUESTS';

export interface LoanApplicationState {
  pagedLoanApplications: PagedList<LoanApplication>,
  loanApplicationDetails: LoanApplication,
  openDocumentRequests: DocumentRequest[]
}

interface GetSubmittedLoansAction {
  type: typeof PAGED_LOAN_APPLICATIONS;
  payload: {
    pagedLoanApplications: PagedList<LoanApplication>;
  }
}

interface GetSubmittedLoanDetailsAction {
  type: typeof LOAN_APPLICATION_DETAILS;
  payload: {
    loanApplicationDetails: LoanApplication;
  }
}

interface AssignLoanOfficerAction {
  type: typeof ASSIGN_LOAN_OFFICER;
  payload: {
    bankEmployee: BankEmployee;
  }
}

interface AddLoanNoteAction {
  type: typeof LOAN_ADD_NOTE;
  payload: {
    loanNote: LoanNote;
  }
}

interface UpdateLoanNoteAction {
  type: typeof LOAN_UPDATE_NOTE;
  payload: {
    updatedLoanNote: LoanNote;
  }
}

interface DeleteLoanNoteAction {
  type: typeof LOAN_DELETE_NOTE;
  payload: {
    loanNoteId: number;
  }
}

interface UpdateLoanStatusAction {
  type: typeof LOAN_UPDATE_STATUS;
  payload: {
    loanStatus: number;
  }
}

interface AddLoanOfferAction {
  type: typeof LOAN_ADD_OFFER;
  payload: {
    loanOffer: LoanOffer;
  }
}

interface AddLoanDocumentAction {
  type: typeof LOAN_ADD_DOCUMENT;
  payload: {
    loanDocuments: LoanDocument[];
  }
}

interface DeleteLoanDocumentAction {
  type: typeof LOAN_DELETE_DOCUMENT;
  payload: {
    loanDocumentId: number;
  }
}

interface FindOpenDocumentRequestsAction {
  type: typeof LOAN_OPENREQUESTS;
  payload: {
    openDocumentRequests: DocumentRequest[];
  }
}

export type LoanApplicationActionTypes = 
    GetSubmittedLoansAction 
  | GetSubmittedLoanDetailsAction 
  | AssignLoanOfficerAction
  | AddLoanNoteAction 
  | UpdateLoanNoteAction
  | DeleteLoanNoteAction
  | UpdateLoanStatusAction
  | AddLoanOfferAction
  | AddLoanDocumentAction
  | FindOpenDocumentRequestsAction
  | DeleteLoanDocumentAction;