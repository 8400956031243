
import LoanApplication from "../../../models/loan/loanApplication";
import PagedList from "../../../models/accessors/pagedList";
import { LoanApplicationActionTypes, LoanApplicationState, PAGED_LOAN_APPLICATIONS, LOAN_APPLICATION_DETAILS, LOAN_ADD_NOTE, LOAN_DELETE_NOTE, LOAN_UPDATE_NOTE, ASSIGN_LOAN_OFFICER, LOAN_UPDATE_STATUS, LOAN_ADD_OFFER, LOAN_ADD_DOCUMENT, LOAN_DELETE_DOCUMENT, LOAN_OPENREQUESTS } from "../../store/loan/types";
import DocumentRequest from "models/documentData/DocumentRequest";

const initialState: LoanApplicationState = {
    pagedLoanApplications: {} as PagedList<LoanApplication>,
    loanApplicationDetails: {} as LoanApplication,
    openDocumentRequests: [] as DocumentRequest[],
};

export default function loans(
    state = initialState,
    action?: LoanApplicationActionTypes
): LoanApplicationState {
    switch (action?.type) {
        case PAGED_LOAN_APPLICATIONS: {
            return {
                ...state,
                pagedLoanApplications: action.payload.pagedLoanApplications
            };
        }

        case LOAN_APPLICATION_DETAILS: {
            return {
                ...state,
                loanApplicationDetails: action.payload.loanApplicationDetails
            };
        }

        case ASSIGN_LOAN_OFFICER: {
            return {
                ...state,
                loanApplicationDetails: {
                    ...state.loanApplicationDetails,
                    bankEmployee: action.payload.bankEmployee
                }
            };
        }

        case LOAN_ADD_NOTE: {
            return {
                ...state,
                loanApplicationDetails: {
                    ...state.loanApplicationDetails,
                    loanNotes: [...state.loanApplicationDetails.loanNotes ?? [], action.payload.loanNote]
                }
            };
        }

        case LOAN_UPDATE_NOTE: {
            let loanApplicationDetails = [...state.loanApplicationDetails.loanNotes ?? []];
            let updatedIndex = loanApplicationDetails.findIndex(ld => ld.id === action.payload.updatedLoanNote.id);
            loanApplicationDetails[updatedIndex] = action.payload.updatedLoanNote;

            return {
                ...state,
                loanApplicationDetails: {
                    ...state.loanApplicationDetails,
                    loanNotes: loanApplicationDetails
                }
            };
        }

        case LOAN_DELETE_NOTE: {
            return {
                ...state,
                loanApplicationDetails: {
                    ...state.loanApplicationDetails,
                    loanNotes: [...(state.loanApplicationDetails.loanNotes ?? []).filter(ln => ln.id !== action.payload.loanNoteId)]
                }
            };
        }

        case LOAN_UPDATE_STATUS: {
            return {
                ...state,
                loanApplicationDetails: {
                    ...state.loanApplicationDetails,
                    status: action.payload.loanStatus
                }
            };
        }

        case LOAN_ADD_OFFER: {
            return {
                ...state,
                loanApplicationDetails: {
                    ...state.loanApplicationDetails,
                    loanOffers: [...state.loanApplicationDetails.loanOffers ?? [], action.payload.loanOffer]
                }
            };
        }

        case LOAN_ADD_DOCUMENT: {
            return {
                ...state,
                loanApplicationDetails: {
                    ...state.loanApplicationDetails,
                    customerAccount: {
                        ...state.loanApplicationDetails.customerAccount,
                        loanDocuments: state.loanApplicationDetails.customerAccount.loanDocuments?.concat(action.payload.loanDocuments)
                    }
                }
            };
        }        

        case LOAN_DELETE_DOCUMENT: {
            return {
                ...state,
                loanApplicationDetails: {
                    ...state.loanApplicationDetails,
                    customerAccount: {
                        ...state.loanApplicationDetails.customerAccount,
                        loanDocuments: [...(state.loanApplicationDetails.customerAccount.loanDocuments ?? []).filter(ln => ln.id !== action.payload.loanDocumentId)]
                    }
                }
            };
        }

        case LOAN_OPENREQUESTS: {
            return {
                ...state,
                openDocumentRequests: action.payload.openDocumentRequests
            };
        }
        
        default:
            return state;
    }
}
