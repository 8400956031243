import React, { BaseSyntheticEvent, useRef, useState } from 'react';
import './DropZone.css';

const DropZone = (props: any) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [validFiles, setValidFiles] = useState<File[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    
    const dragOver = (e: BaseSyntheticEvent) => {
        e.preventDefault();
    }
    
    const dragEnter = (e: BaseSyntheticEvent) => {
        e.preventDefault();
    }
    
    const dragLeave = (e: BaseSyntheticEvent) => {
        e.preventDefault();
    }
    
    const fileDrop = (e: React.DragEvent) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
        }
    }

    const filesSelected = () => {
        if (fileInputRef.current && fileInputRef.current.files?.length) {
            handleFiles(fileInputRef.current.files);
        }
    }

    const fileInputClicked = () => {
        if(fileInputRef.current && fileInputRef.current !== undefined){
            fileInputRef.current.click();
        }
    }

    const handleFiles = (files: any) => {

        for(const file of files) {
            if (validateFile(file)) {
                // add to an array so we can display the name of file
                selectedFiles.push(file);
                validFiles.push(file);
            } else {
                // add a new property called invalid
                file['invalid'] = true;
                // add to the same array so we can display the name of the file
                selectedFiles.push(file);
                // set error message
                setErrorMessage('File type not permitted');              
            }
        }   
        props.fileListChange(validFiles);
        if(fileInputRef.current){
            fileInputRef.current.value = "";
        }
    }

    const validateFile = (file: File) => {
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon', 'application/pdf'];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }
        return true;
    }

    const fileSize = (size: number) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }
    
    const fileType = (fileName: string) => {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }

    const removeFile = (name: string) => {
        // find the index of the item
        // remove the item from array
    
        const validFileIndex = validFiles.findIndex((e: File) => e.name === name);
        validFiles.splice(validFileIndex, 1);
        // update validFiles array
        setValidFiles([...validFiles]);
        const selectedFileIndex = selectedFiles.findIndex((e: File) => e.name === name);
        selectedFiles.splice(selectedFileIndex, 1);
        // update selectedFiles array
        setSelectedFiles([...selectedFiles]);
        props.fileListChange(validFiles);
    }

    return (
        <>
        <div className="outer-drop-container">
            <div className="drop-container"
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                onClick={fileInputClicked}
            >
                <div className="drop-message">
                    <div className="upload-icon"></div>
                    Drag & Drop files here or click to upload
                </div>
                <input
                    ref={fileInputRef}
                    className="file-input"
                    type="file"
                    multiple
                    onChange={filesSelected}
                />
            </div>
            </div>
            <div className="file-display-container">
                {selectedFiles.map((data: any, i: number) => 
                    <div className="file-status-bar" key={i}>
                        <div>
                            <div className="file-type-logo"></div>
                            <div className="file-type">{fileType(data.name)}</div>
                            <span className={`file-name ${data.invalid ? 'file-error' : ''}`}>{data.name}</span>
                            <span className="file-size">({fileSize(data.size)})</span> {data.invalid && <span className='file-error-message'>({errorMessage})</span>}
                            <div className="file-remove" onClick={() => removeFile(data.name)}>X</div>
                        </div>
                        
                    </div>
                )}
            </div>
        
        </>
    )
}
export default DropZone;