import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import SecondaryButton from "shared/components/SecondaryButton";
import "./IdDocumentModal.css"
import 'react-calendar/dist/Calendar.css';
import LoadingSpinner from "shared/components/spinner/LoadingSpinner";

type callback = () => void;

interface IdDocumentModalProps {
  showModal: boolean;
  image: string;
  isLoading: boolean;
  onModalCancel: callback;
  onModalConfirm: callback;
}

export default class IdDocumentModal extends React.Component<IdDocumentModalProps> {

  constructor(props: any) {
    super(props);

  }

  componentDidUpdate(previousProps: IdDocumentModalProps): void {

  }

  resetModalState(): void {
    
  }

  onModalConfirmAction(): void {
    this.props.onModalConfirm();
  }

  onModalCancelAction(): void {
    this.props.onModalCancel();
  }

  render() {
    return (
      <Modal show={this.props.showModal} onHide={this.onModalCancelAction.bind(this)} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{'Verification Document Preview'} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='center-img'>
            <img alt="" src={this.props.image} width="400"/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton buttonText={'Close'} onClick={this.onModalCancelAction.bind(this)} />
        </Modal.Footer>
        {this.props.isLoading &&
          <LoadingSpinner
            variant={'light'}
            animation={'border'}
          />
        }
      </Modal>
    );
  }
}